import {Auth0Provider} from '@auth0/auth0-react';
import React from 'react';

const Auth0ProviderWithConfig = ({ children }) => {
    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const callbackUri = process.env.REACT_APP_AUTH0_CALLBACK_URI;

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={callbackUri}
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithConfig;