import React, { Component } from 'react';
import {useAuth0} from "@auth0/auth0-react";
import Box from "@mui/material/Box";

const Auth0Guard = ({showLoading = true, showDenied = true, children}) => {

    const { isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {

        if(showLoading){
            return <div className={'pp-card'}>
                <Box>
                    <h3>Loading</h3>
                </Box>
            </div>;
        }else{
            return <></>;
        }


    }else if(! isAuthenticated){

        if(showDenied) {
            return <div className={'pp-card'}>
                <Box>
                    <h3>Access Denied</h3>
                </Box>
            </div>;
        }else{
            return <></>;
        }

    }else{
        return (<>{children}</>);
    }

}

export default Auth0Guard;