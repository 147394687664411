import React from 'react';
import Box from "@mui/material/Box";

const Home = () => {
    return (
        <div className={'pp-card'}>
            <Box>
                <h3>Home Page</h3>
            </Box>
        </div>
    );
}

export default Home;