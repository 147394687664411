import React from 'react';
import Box from "@mui/material/Box";
import Auth0Guard from "../components/Auth0Guard";
import PowerBIDemo from "../components/PowerBIDemo";

const Protected = () => {
    return (
        <Auth0Guard>
            <div className={'pp-card'}>
                <Box>
                    <h3>Protected Page</h3>
                </Box>
            </div>
            <PowerBIDemo />
        </Auth0Guard>
    );
}

export default Protected;