import React from 'react';
import {Outlet} from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import NavBar from "./components/Header";

const theme = createTheme({
    typography: {
        fontFamily: ['Arial', 'Sans Serif'].join(",")
    }
});

const Layout = () => {
    return (
        <>
            <ThemeProvider theme={theme}>
                <div className="App">
                    <NavBar />
                    <Outlet />
                </div>
            </ThemeProvider>
        </>
    );
};

export default Layout;