import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LogoutButton from "./LogoutButton";
import LoginButton from "./LoginButton";

const AuthenticationButton = ({forceAuthenticated}) => {

    let { isAuthenticated } = useAuth0();

    if (forceAuthenticated) {
        isAuthenticated = forceAuthenticated;
    }
    return isAuthenticated ? <LogoutButton /> : <LoginButton />;
};

export default AuthenticationButton;