import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Auth0ProviderWithConfig from './components/Auth0ProviderWithConfig';
import Layout from './Layout';
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Protected from "./pages/Protected";

export const App = () => {

    return (
        <BrowserRouter>
            <Auth0ProviderWithConfig>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Home />} />
                        <Route path="protected" element={<Protected />} />
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Routes>
            </Auth0ProviderWithConfig>
        </BrowserRouter>
    );
}