import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Slide, useScrollTrigger,
    CssBaseline, AppBar,
    Toolbar, Typography, IconButton,
} from "@mui/material";
import AuthenticationButton from "../Button/AuthenticationButton";
import MenuIcon from '@mui/icons-material/Menu';
import {Link} from "react-router-dom";
import Auth0Guard from "../Auth0Guard";


function HideOnScroll(props) {
    const { children, window } = props;

    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,

    window: PropTypes.func,
};

export default function NavBar(props) {
    return (
        <div data-testid="navBar">
            <React.Fragment >
                <CssBaseline />
                <HideOnScroll {...props}>
                    <AppBar position="static">
                        <Toolbar sx={{backgroundColor: '#b11116'}}>
                            <Auth0Guard showLoading={false} showDenied={false}>
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2 }}
                                >
                                    <Link to="/protected" style={{textDecoration: 'none', color: "white"}}>
                                        <MenuIcon />
                                    </Link>
                                </IconButton>
                            </Auth0Guard>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                <a style={{textDecoration: 'none', color: "white"}} href="/">PeoplePlus | Reports</a>
                            </Typography>
                            <div className={'right-align'}><AuthenticationButton/></div>
                        </Toolbar>
                    </AppBar>
                </HideOnScroll>
            </React.Fragment>
        </div>
    );
}